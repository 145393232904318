<template>
  <v-app>
    <v-row align-content="center">
      <v-col align="center">
        <v-card outlined max-width="350">
          <v-card-title>パスワード再発行</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-text-field
                  prepend-icon="mdi-account-circle"
                  v-model="credentials.userId"
                  label="登録メールアドレス"
                  :rules="[rules.userId, rules.email]"
                  required
                />
              </v-container>
            </v-form>

            <v-spacer />
            <v-row justify="center">
              <v-col>
                <v-btn
                  :disabled="!valid"
                  x-large
                  block
                  color="primary"
                  @click="reset"
                  >パスワード再発行</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-btn text to="/login" class="mt-4">
          <span class="text-decoration-underline">ログイン画面へ戻る</span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- snack -->
    <v-snackbar v-model="snack" :timeout="snackTimeout" :color="snackColor">
      {{ snackText }}
    </v-snackbar>

    <v-overlay v-show="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Login",
  data: () => ({
    loginStatus: "",
    loading: false,
    showPassword: false,
    valid: true,
    credentials: {},
    rules: {
      userId: (v) => !!v || "必須入力です",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "無効なメールアドレスです";
      },
    },

    // snack
    snack: false,
    snackTimeout: -1,
    snackColor: "",
    snackText: "",
  }),
  async mounted() {},
  computed: {
    ...mapGetters(["progress"]),
  },
  methods: {
    ...mapActions(["sendPasswordResetEmail"]),
    async reset() {
      // 入力チェック
      const submit = this.$refs.form.validate();
      if (!submit) {
        return;
      }

      // 画面入力値
      const email = this.credentials.userId;

      // パスワードリセット送信
      const result = await this.sendPasswordResetEmail({
        email,
      });
      if (result) {
        // ログイン画面へ遷移
        // this.$router.replace("/login");
        this.snack = true;
        this.snackColor = "success";
        this.snackText =
          "パスワードリセットメールを送信しました。メールをご確認ください。";
      } else {
        // 登録されていないユーザ
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "登録されていないユーザです";
      }
    },
  },
};
</script>
